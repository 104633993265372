// extracted by mini-css-extract-plugin
export var blockContent = "project-module--blockContent--4a1b6";
export var browserHeader = "project-module--browserHeader--1de25";
export var browserHeaderCircle = "project-module--browserHeaderCircle--deabd";
export var browserWindow = "project-module--browserWindow--868fb";
export var btn = "project-module--btn--78b19";
export var btnWrap = "project-module--btnWrap--419a9";
export var buttonBack = "project-module--buttonBack--1ad55";
export var buttonNext = "project-module--buttonNext--23310";
export var carouselWrap = "project-module--carouselWrap--bad66";
export var dotGroup = "project-module--dotGroup--54738";
export var fitVid = "project-module--fitVid--bda3c";
export var green = "project-module--green--f6080";
export var grid = "project-module--grid--2ef41";
export var headerBtns = "project-module--headerBtns--01eaf";
export var linkBtn = "project-module--linkBtn--cf8c0";
export var navArrow = "project-module--navArrow--3b5f9";
export var pageIcon = "project-module--pageIcon--33869";
export var pauseBtn = "project-module--pauseBtn--890f2";
export var playBtn = "project-module--playBtn--18fb2";
export var portfolioCopy = "project-module--portfolioCopy--5c903";
export var portfolioVideo = "project-module--portfolioVideo--d0396";
export var projectError = "project-module--projectError--90ba6";
export var projectInfo = "project-module--projectInfo--f2389";
export var projectLoader = "project-module--projectLoader--9afc8";
export var projectMeta = "project-module--projectMeta--c2b82";
export var projectSlider = "project-module--projectSlider--9e63e";
export var projectTitle = "project-module--projectTitle--fe4ce";
export var red = "project-module--red--aaa39";
export var relatedProjects = "project-module--relatedProjects--30165";
export var slideImg = "project-module--slideImg--3816a";
export var sliderWrap = "project-module--sliderWrap--d2815";
export var smallHeader = "project-module--smallHeader--f1923";
export var tags = "project-module--tags--7bd79";
export var videoWrap = "project-module--videoWrap--b7e2f";
export var wrap = "project-module--wrap--aba21";
export var yellow = "project-module--yellow--2609f";