import React from 'react';
import { graphql } from 'gatsby';
import Container from '../components/container';
import GraphQLErrorList from '../components/graphql-error-list';
import Project from '../components/project';
import SEO from '../components/seo';
import LayoutContainer from '../containers/layout-container';

export const query = graphql`
  query ProjectTemplateQuery($id: String!) {
    sanityProject: sanityProject(id: { eq: $id }) {
      id
      youtubeId
      videoPoster {
        alt
        asset {
          _id
          url
          title
          assetId
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      _rawBackground
      _rawProcess
      carousel {
        title
        slides {
          image {
            alt
            asset {
              _id
              assetId
              url
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      categories {
        title
        id
      }
      clientName
      employerName
      launchDate(formatString: "MMMM YYYY")
      projectName
      projectTags
      projectThumbnail {
        alt
        asset {
          assetId
          _id
          gatsbyImageData(layout: FIXED, width: 349)
          url
        }
      }
      projectUrl
      title
      video {
        asset {
          url
          title
          mimeType
          assetId
        }
      }
      slug {
        current
      }
      relatedProjects {
        _id
        slug {
          current
        }
        title
      }
    }
  }
`;

const ProjectTemplate = (props) => {
  const { data, errors, pageContext } = props;
  const { previous } = pageContext;
  const { next } = pageContext;
  const project = data && data.sanityProject;
  return (
    <LayoutContainer isProjectPage={true}>
      <main>
        {errors && <SEO title="GraphQL Error" />}
        {project && <SEO title={project.title || 'Untitled'} image={project.projectThumbnail} />}

        {errors && (
          <Container>
            <GraphQLErrorList errors={errors} />
          </Container>
        )}
        {project && <Project {...project} pagination={[previous, next]} />}
      </main>
    </LayoutContainer>
  );
};

export default ProjectTemplate;
